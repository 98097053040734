<template>
    <div class="page">
        <app-header :navIndex="2"></app-header>
        <banner :data="banner"></banner>
        <section class="mb-12">
            <div class="top-box section-container logo-box">
                <div class="top-border hidden-md-and-up white"></div>
                <div class="inner d-flex flex-column flex-md-row align-md-center">
                    <div class="img-box">
                        <v-img class="img" contain :src="$utils.formatImgUrl(details.logoUrl)"></v-img>
                    </div>
                    <div class="ml-5">
                        <div class="name">{{details.title}}</div>
                        <div class="enName mt-2 mb-2">{{details.titleEn}}</div>
                        <div class="d-flex flex-column flex-md-row">
                            <!--<div class="link mr-5" v-for="(item,index) in details.linkList"
                                 :key="index" v-if="item.link||item.title">
                                <a v-if="item.link" :href="'https://'+item.link" target="_blank">
                        <span class="mr-3 iconfont"
                              :class="item.iconfont"></span>{{item.title}}</a>
                                <a v-else><span class="mr-3 iconfont"
                                                :class="item.iconfont"></span>{{item.title}}</a>
                            </div>-->
                            <div class="link mr-5">
                                <span class="mr-3 iconfont iconguojia"></span>{{details.country}}
                            </div>
                            <div class="link mr-5" v-if="details.linkUrl">
                                <a :href="'https://'+details.linkUrl" target="_blank"><span
                                        class="mr-3 iconfont iconwangzhi"></span>{{details.linkUrl}}</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!--<div class="section-container mt-md-12 mb-md-12" v-if="false">
                <div class="pl-6 pl-md-2 pr-6 pr-md-0 pt-md-2 link" v-for="(item,index) in details.linkList"
                     :key="index" v-if="item.link||item.title">
                    <a v-if="item.link" :href="'https://'+item.link" target="_blank">
                        <span class="mr-3 iconfont"
                              :class="item.iconfont"></span>{{item.title}}</a>
                    <a v-else><span class="mr-3 iconfont"
                                    :class="item.iconfont"></span>{{item.title}}</a>
                </div>
            </div>-->
            <div class="d-flex flex-column flex-md-row justify-md-end align-md-start align-center ma-2 ma-md-0">
                <div class="desc col-md-5 section-container" v-html="details.desc"></div>
                <div class="col-md-6 mt-md-3 pa-0">
                    <v-img contain :src="$utils.formatImgUrl(details.imgUrl)"></v-img>
                </div>
            </div>
        </section>
        <!--荣誉奖项-->
        <section class="section-container mt-md-12 pt-8 mb-8 pt-md-12 pb-md-12" v-if="false">
            <section-title :title="award.title" :enTitle="award.enTitle"></section-title>
            <div class="d-flex flex-wrap flex-md-row mt-6 mt-md-12">
                <div class="col-6 col-md-3" v-for="(item,index) in award.items" :key="index">
                    <v-img class="ml-12 mr-12" :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                    <div class="desc text-center">{{item.title}}</div>
                    <div class="desc text-center">{{item.desc}}</div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Banner from "../../components/base/banner";
    import SectionTitle from "../company/components/section-title";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            SectionTitle,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: ''
                },
                details: {},
                award: {
                    title: '荣誉奖项',
                    enTitle: 'AWARD',
                    items: [{
                        title: '拉菲罗斯柴尔德古堡',
                        desc: '1982年份 获得罗伯特帕克满分评价',
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/img_three.jpg')
                    }, {
                        title: '拉菲罗斯柴尔德古堡',
                        desc: '1982年份 获得罗伯特帕克满分评价',
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/img_three.jpg')
                    }, {
                        title: '拉菲罗斯柴尔德古堡',
                        desc: '1982年份 获得罗伯特帕克满分评价',
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/img_three.jpg')
                    }, {
                        title: '拉菲罗斯柴尔德古堡',
                        desc: '1982年份 获得罗伯特帕克满分评价',
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/img_three.jpg')
                    }]
                }
            }
        },
        created(){
//            this.details = this.$i18n.t('others.wineries.details' + this.$route.query.id)
//            this.banner.imgUrl = this.details.bannerImg
//            if (this.details.items) {
//                this.award.items = this.details.items
//            }
            this.$api.web.getProductDetails({id: this.$route.query.id}).then(res => {
                this.banner.imgUrl = res.bannerUrl
                this.details = res
            })
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "酒庄"
            document.getElementById("bodyId").appendChild(div);
        },
    }
</script>
<style lang="scss" scoped>
    .logo-box {
        /* margin-top: -150px;*/
        .inner {
            /*width: 400px;*/
            /*height: 400px;*/
            /*background-color: white;*/
            /*border-top-left-radius: 20px;*/
            /*box-shadow: 0px 2px 16px 0px rgba(156, 156, 156, 0.4);*/
            padding: 20px;
            position: relative;
            .img-box {
                width: 300px;
                height: 175px;
                .img {
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
                font-size: 30px;
                /*text-align: center;*/
            }
            .enName {
                font-size: 18px;
                /*text-align: center;*/
            }
        }
    }

    .link {
        font-size: 16px;
        color: $primary-color;
    }

    @media (max-width: $screen-md) {
        .logo-box {
            margin-top: 0;
            .inner {
                /*width: 100%;*/
                /*height: auto;*/
                /*padding: 24px;*/
                /*border-top-left-radius: 0;*/
                /*box-shadow: none;*/
                .img {
                    width: 174px;
                    height: 108px;
                }
                .name {
                    font-size: 20px;
                    text-align: left;
                }
                .enName {
                    font-size: 14px;
                    text-align: left;
                }
            }
        }
        .link {
            font-size: 14px;
        }
    }
</style>
